import React, { Component } from "react"
import styled from "styled-components"
import { IoIosClose } from "react-icons/io"
import TextareaAutosize from "react-autosize-textarea"
import EmailValidator from "email-validator"
import Fade from "react-reveal/Fade"
import { colors } from "../style/colors"
import { CopyToClipboard } from "react-copy-to-clipboard"

const titleColors = ["#F9ED24", "#ff8345", "#0FBCBC", "#EFE1BA"]
const selectedColor =
  titleColors[Math.floor(Math.random() * titleColors.length)]

const Container = styled.div`
  /* width: ${props => (props.open ? "100vw" : "0px")}; */
  width: 100vw;
  opacity: ${props => (props.open ? 1 : 0)};
  min-height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => (props.open ? 100 : -1)};
  transition: 0.3s ease-out;
  pointer-events: ${props => !props.open && "none"};

  @media (max-width: 900px) {
    grid-template-columns: none;
    grid-template-rows: auto auto;
  position: absolute;

  }
`
const CloseContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 101;
  cursor: pointer;
  @media (max-width: 900px) {
    left: 20px;
    top: 10px;

    svg {
      background-color: black;
    }
  }
`
const ContactContainer = styled.div`
  background-color: #1a1818;
  padding: 100px 50px;
  position: relative;
  display: ${props => !props.open && "none"};
  z-index: ${props => (props.open ? 100 : -1)};
  overflow-y: scroll;

  @media (max-width: 900px) {
    padding: 40px 20px;
    overflow-y: visible;
  }
`
const StaticContainer = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  flex-direction: column;
  padding: 40px;
  justify-content: center;
  display: ${props => !props.open && "none"};
  align-items: center;
  z-index: ${props => (props.open ? 100 : -1)};
  max-height: 100vh;

  text-align: center;

  @media (max-width: 900px) {
    grid-row: 1;
    padding-top: 60px;
  }
`
const Intro = styled.p`
  color: white;
  font-size: 24px;
  line-height: 33px;
  transition: 0.3s ease;
  color: ${props => (props.grey ? "#727272" : "white")};

  @media (max-width: 900px) {
    font-size: 18px;
    grid-row: 1;
  }
`
const Greeting = styled.input`
  color: #727272;
  font-size: 24px;
  line-height: 33px;
  font-family: "Recursive";
  font-variation-settings: "wght" 474, "PROP" 1, "XPRN" 0, "slnt" -15;
  margin-top: 35px;
  margin-bottom: 1.45rem;
  color: ${props => (props.grey ? "white" : "#727272")};

  border: none;
  background: none;
  line-height: 33px;
  font-size: 24px;
  outline: none;
  /* color: white; */
  width: 100%;

  ::placeholder {
    color: #727272;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }
`
const Textarea = styled.div`
  textarea {
    background-color: none;
    background: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid white;
    height: auto;
    font-family: "Recursive";
    line-height: 33px;
    font-size: 24px;
    font-variation-settings: "wght" 474, "PROP" 0, "XPRN" 0;
    color: white;
    outline: none;
    resize: none;

    margin-bottom: 30px;

    :focus {
      outline: none;
    }

    @media (max-width: 900px) {
      font-size: 18px;
    }
  }
`

const Input = styled.input`
  border: none;
  background: none;
  font-family: "Recursive";
  line-height: 33px;
  font-size: 24px;
  font-variation-settings: "wght" 474, "PROP" 1, "XPRN" 0;
  outline: none;
  color: white;
  width: 100%;

  ::placeholder {
    color: #727272;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }
`
const SubmitBtn = styled.button`
  border: 1px solid white;
  background-color: none;
  background: none;
  font-variation-settings: "wght" 474, "PROP" 1, "XPRN" 0;
  font-family: "Recursive";
  outline: none;
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  padding: 20px 30px;
  margin-top: 40px;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 0;
  }

  :disabled {
    border: 1px solid #727272;
    color: #727272;
    cursor: not-allowed;

    :hover {
      font-variation-settings: "wght" 474, "PROP" 1, "XPRN" 0;
    }
  }

  :focus {
    outline: none;
  }

  @media (max-width: 900px) {
    font-size: 18px;
    padding: 18px 20px;
  }
`
const Guidance = styled.p`
  color: ${colors.red};
  margin: 10px 0 0 0;
`
const BigTitle = styled.h1`
  font-size: 13vw;
  line-height: 90%;
  transform: rotate(-2.35deg);
  font-variation-settings: "wght" 750, "PROP" 0, "XPRN" 1, "CASL" 1;
  text-transform: uppercase;
  color: black;
  letter-spacing: -8px;

  @media (max-width: 700px) {
    letter-spacing: -5px;
  }

  @media (max-width: 550px) {
    letter-spacing: -3px;
  }
`
const Subtext = styled.p`
  max-width: 450px;
  font-variation-settings: "wght" 450, "PROP" 1, "XPRN" 0;
  margin: 0 auto;
`
const Bold = styled.span`
  font-variation-settings: "wght" 750, "PROP" 1, "XPRN" 0;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 0;
    /* color: ${selectedColor}; */
  }
`
const Form = styled.form``
const EmailCopied = styled.div`
  height: 50px;
  background-color: ${selectedColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  transition: 0.3s ease;
  opacity: ${props => (props.copied ? 1 : 0)};
  bottom: ${props => (props.copied ? 0 : "-50px")};

  p {
    margin: 0;
    color: white;
    color: ${selectedColor === "#F9ED24" && "black"};
    color: ${selectedColor === "#EFE1BA" && "black"};
    text-transform: uppercase;
    font-variation-settings: "wght" 750, "PROP" 1, "XPRN" 0;
  }
`
const Spacing = styled.span`
  /* letter-spacing: -16px; */

  /* @media (max-width: 1000px) {
    letter-spacing: -12px;
  }

  @media (max-width: 780px) {
    letter-spacing: -8px;
  }

  @media (max-width: 650px) {
    letter-spacing: -5px;
  }

  @media (max-width: 550px) {
    letter-spacing: -10px;
  }

  @media (max-width: 400px) {
    letter-spacing: -10px;
  } */
`
const Subspacing = styled.span`
  /* letter-spacing: -26px; */

  /* @media (max-width: 1000px) {
    letter-spacing: -19px;
  }

  @media (max-width: 780px) {
    letter-spacing: -14px;
  }

  @media (max-width: 650px) {
    letter-spacing: -12px;
  }

  @media (max-width: 550px) {
    letter-spacing: -14px;
  }

  @media (max-width: 400px) {
    letter-spacing: -12px;
  } */
`
class ContactFlyout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      message: "",
      greeting: "",
      wishes: "",
      name: "",
      email: "",
      validEmail: null,
      copied: false,
    }
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = () => {
    // alert("Thanks for getting in touch!")
    this.props.closeMenu()
  }

  checkEmail = () => {
    if (EmailValidator.validate(this.state.email)) {
      this.setState({ validEmail: true })
    } else {
      this.setState({ validEmail: false })
    }
  }

  handleCopy = () => {
    this.setState({ copied: true }, () => {
      //Set a timeout for three seconds, and return to not copied
      setTimeout(() => {
        this.setState({ copied: false })
      }, 3000)
    })
  }

  render() {
    const { editing, name, email, message, validEmail } = this.state
    return (
      <Container open={this.props.open}>
        <CloseContainer onClick={() => this.props.closeMenu()}>
          <IoIosClose color={"white"} size={40} />
        </CloseContainer>
        <ContactContainer open={this.props.open}>
          <Intro grey={editing}>
            Thank you for your interest in working with me. I promise you can reach me by filling out the form below. I look forward to connecting with you.
          </Intro>
          {/* <Greeting grey={editing}> */}
          <label htmlFor="greeting">
            <Greeting
              grey={this.state.editing}
              type="text"
              placeholder="Hi Rochelle,"
              name="greeting"
              title="Greeting"
              id="greeting"
              value={this.state.greeting}
              onChange={this.updateInput}
            />
          </label>
          {/* </Greeting> */}
          <Form
            onSubmit={this.handleSubmit}
            action="https://formsubmit.io/send/rochelleamour@gmail.com"
            method="POST"
          >
            <Textarea>
              <label htmlFor="message">
                <TextareaAutosize
                  onFocus={() => this.setState({ editing: true })}
                  onBlur={() => this.setState({ editing: false })}
                  cols={1}
                  placeholder="Start your message here..."
                  name="message"
                  id="message"
                  title="Message"
                  style={{ resize: "none" }}
                  value={this.state.message}
                  onChange={this.updateInput}
                />
              </label>
            </Textarea>
            {/* <Intro grey={editing}>Best wishes,</Intro> */}
            <label htmlFor="wishes">
              <Greeting
                grey={this.state.editing}
                type="text"
                placeholder="Best Wishes,"
                name="wishes"
                title="Salutations"
                id="wishes"
                value={this.state.wishes}
                onChange={this.updateInput}
              />
            </label>
            <label htmlFor="name">
              <Input
                onFocus={() => this.setState({ editing: true })}
                onBlur={() => this.setState({ editing: false })}
                type="text"
                placeholder="Enter your name"
                name="name"
                title="Full Name"
                id="name"
                value={this.state.name}
                onChange={this.updateInput}
              />
            </label>
            <br />
            <label htmlFor="email">
              <Input
                onFocus={() => this.setState({ editing: true })}
                onBlur={
                  (() => this.setState({ editing: false }), this.checkEmail)
                }
                type="email"
                placeholder="Enter your email"
                name="email"
                id="email"
                title="Email Address"
                value={this.state.email}
                onChange={this.updateInput}
              />
              <br />
            </label>
            <SubmitBtn
              type="submit"
              disabled={
                message === "" || email === "" || name === "" || !validEmail
              }
            >
              Get in touch
            </SubmitBtn>
            {validEmail === false && (
              <Fade>
                <Guidance>Please enter a valid email</Guidance>
              </Fade>
            )}
          </Form>
        </ContactContainer>
        <StaticContainer open={this.props.open}>
          <BigTitle>
            Le
            <Spacing>
              t<Subspacing>&#8217;s</Subspacing>
            </Spacing>{" "}
            Talk
          </BigTitle>
          <Subtext>
            you can also email{" "}
            <CopyToClipboard
              text="rochelleamour@gmail.com"
              onCopy={this.handleCopy}
            >
              <Bold>rochelleamour@gmail.com</Bold>
            </CopyToClipboard>{" "}
            if contact forms aren’t your cup of tea
          </Subtext>
          <EmailCopied copied={this.state.copied}>
            <p>Email copied to the clipboard</p>
          </EmailCopied>
        </StaticContainer>
      </Container>
    )
  }
}

export default ContactFlyout
