import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import BackgroundTitle from "../components/background-title"
import { colors } from "../style/colors"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { CopyToClipboard } from "react-copy-to-clipboard"

const MainContainer = styled.div`
  background-color: ${colors.beige};
`
const Container = styled.section`
  padding: 350px 90px 70px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  z-index: 2;
  font-size: 25px;
  line-height: 120%;

  p {
    z-index: 2;
    font-size: 24px;
    line-height: 120%;
    font-variation-settings: "wght" 550, "PROP" 1, "XPRN" 0;
  }

  p:first-child {
    font-variation-settings: "wght" 800, "PROP" 1, "XPRN" 1;
  }

  @media (max-width: 800px) {
    padding: 250px 30px 70px;
  }
`
const ConnectionsContainer = styled.section`
  display: grid;
  background-color: black;
  margin: 0 auto;
  padding: 50px 90px;
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 50px 30px;
  }
`
const Connection = styled.div``
const Title = styled.h1`
  color: ${colors.beige};
  font-size: 26px;
  font-variation-settings: "wght" 750, "PROP" 1, "XPRN" 1;
  margin-bottom: 30px;
  text-transform: uppercase;
`
const Description = styled.p`
  margin: 0 50px 20px 0;
  font-size: 22px;
  line-height: 140%;
  color: white;

  @media (max-width: 800px) {
    margin: 0 10px 40px 0;
  }
`
const Contact = styled.p`
 margin: 0 50px 10px 0;
  font-size: 20px;
  line-height: 140%;
  color: ${colors.beige};
  cursor: pointer;

  :last-child {
    margin-bottom: 40px;
  }

  @media (max-width: 800px) {
    margin: 0 10px 40px 0;
  }
`
const Link = styled.a`
  color: inherit;
  text-decoration: none;
`
const NotificationBox = styled.div`
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: ${colors.beige};
  color: black;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.14);
  transition: 0.3s ease;
  opacity: ${props => props.show ? 1 : 0};

  p {
    margin: 0;
    
  }
`

class ConnectionsPage extends React.PureComponent {
  state = {}

  handleCopy = () => {
    this.setState({ copied: true }, () => {
      //Set a timeout for three seconds, and return to not copied
      setTimeout(() => {
        this.setState({ copied: false })
      }, 3000)
    })
  }

  render() {
    const data = this.props.data.allWordpressPage.edges[0].node
    return (
      <Layout page="Connections">
        <SEO title="Connections" />
        <NotificationBox show={this.state.copied}>
          <p>Email copied. Paste it anywhere</p>
        </NotificationBox>
        <MainContainer>
          <BackgroundTitle visible top left title="Conne-ctions" />
          <Container dangerouslySetInnerHTML={{ __html: data.content }} />
          <ConnectionsContainer>
            <Connection>
              <Title>{data.acf.name_1}</Title>
              <Description>{data.acf.description_one}</Description>
              <CopyToClipboard
                text={data.acf.email_1}
                onCopy={this.handleCopy}>
                <Contact>{data.acf.email_1 && `Email: ${data.acf.email_1}`}</Contact>

              </CopyToClipboard>
              {data.acf.website_1 && <Contact>Website: <Link target="_blank" href={data.acf.website_1}>{data.acf.website_1}</Link></Contact>}
            </Connection>
            <Connection>
              <Title>{data.acf.name_2}</Title>
              <Description>{data.acf.description_2}</Description>
              <CopyToClipboard
                text={data.acf.email_2}
                onCopy={this.handleCopy}
              >
                <Contact>{data.acf.email_2 && `Email: ${data.acf.email_2}`}</Contact>
              </CopyToClipboard>
              {data.acf.website_2 && <Contact>Website: <Link target="_blank" href={data.acf.website_2}>{data.acf.website_2}</Link></Contact>}
            </Connection>
            <Connection>
              <Title>{data.acf.name_3}</Title>
              <Description>{data.acf.description_3}</Description>
              <CopyToClipboard
                text={data.acf.email_3}
                onCopy={this.handleCopy}
              >
                <Contact>{data.acf.email_3 && `Email: ${data.acf.email_3}`}</Contact>
              </CopyToClipboard>
              {data.acf.website_3 && <Contact>Website: <Link target="_blank" href={data.acf.website_3}>{data.acf.website_3}</Link></Contact>}
            </Connection>
            <Connection>
              <Title>{data.acf.name_4}</Title>
              <Description>{data.acf.description_4}</Description>
              <CopyToClipboard
                text={data.acf.email_4}
                onCopy={this.handleCopy}
              >
                <Contact>{data.acf.email_4 && `Email: ${data.acf.email_4}`}</Contact>
              </CopyToClipboard>
              {data.acf.website_4 && <Contact>Website: <Link target="_blank" href={data.acf.website_4}>{data.acf.website_4}</Link></Contact>}
            </Connection>
          </ConnectionsContainer>
        </MainContainer>
      </Layout>
    )
  }
}

export default ConnectionsPage

export const connnectionsQuery = graphql`
  query($homepage: String = "Connections") {
    allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          content
          acf {
            name_1
            name_2
            name_3
            name_4
            description_one
            description_2
            description_3
            description_4
            email_1
            email_2
            email_3
            email_4
            website_1
            website_2
            website_3
            website_4
          }
        }
      }
    }
  }
`
