import React from "react"
import styled, { keyframes } from "styled-components"
import { colors } from "../style/colors"
import "./layout.css"
import "./background-title.css"

const bigger = keyframes`
  0% {
    font-variation-settings: "wght" 330, "PROP" 1, "XPRN" 1, "CASL" 1;
  }

  50% {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1, "CASL" 1;
  }

  100% {
    font-variation-settings: "wght" 850, "PROP" 1, "XPRN" 1, "CASL" 1;
  }
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(0px,0px)
    /* transform: rotate(0deg); */
  }
  100% {
    opacity: 1;
    transform: translate(10px, 10px)
    /* transform: rotate(-2deg); */
  }
`

const Container = styled.div`
  position: ${props => (props.relative ? "relative" : "absolute")};
  z-index: 0;
  /* left: ${props => props.left && !props.relative && "5%"}; */
  padding-left: ${props => props.left && "5%"};
  padding: ${props => props.relative && "90px 10px 0 5%"};
  right: ${props => (props.right ? "5%" : "5%")};
  top: ${props => props.top && "10%"};
  transform: rotate(-2deg);
  opacity: ${props => (props.opacity ? 0.9 : 1)};
`
const Title = styled.h1`
  color: ${props => (props.yellow ? colors.yellow : "#ffffff")};
  color: ${props => props.black && "#000000"};
  color: ${props => props.blue && colors.blue};
  text-transform: uppercase;
  width: 100%;
  white-space: pre-wrap;
  font-size: 22vw;
  line-height: 90%;
  text-align: ${props => (props.left ? "left" : "right")};
  word-break: break-word;
  /* hyphens: manual; */
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
  /* transform: translateX(15px); */

  span {
    animation: ${bigger} forwards;
    animation-duration: 0.4s;
    animation-delay: 0s;
    animation-timing-function: cubic-bezier(0.14, 0.45, 0.86, 0.65);
    /* animation-timing-function: cubic-bezier(0.26, 0.25, 0.68, 0.27); */
    font-variation-settings: "wght" 330, "PROP" 1, "XPRN" 1, "CASL" 1;

    /* animation-delay: 1s; */
    /* animation-iteration-count: 3; */
    /* font-variation-settings: "wdth" 70, "PROP" 1, "XPRN" 1; */
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.4s;
  }
  span:nth-child(4) {
    animation-delay: 0.6s;
  }
  span:nth-child(5) {
    animation-delay: 1.5s;
  }
  span:nth-child(6) {
    animation-delay: 1.6s;
  }
  span:nth-child(7) {
    animation-delay: 1.7s;
  }
  span:nth-child(8) {
    animation-delay: 1.8s;
  }
  span:nth-child(9) {
    animation-delay: 1.9s;
  }
  span:nth-child(10) {
    animation-delay: 2s;
  }
  span:nth-child(11) {
    animation-delay: 2.1s;
  }
  span:nth-child(12) {
    animation-delay: 2.2s;
  }
  span:nth-child(13) {
    animation-delay: 2.3s;
  }
`

class BackgroundTitle extends React.PureComponent {
  state = {
    title: [],
    visible: this.props.visible,
    width: "200px",
  }

  componentDidMount() {
    const title = this.props.title.split(" ")
    this.setState({ title })
    if (typeof window !== "undefined") {
      let vw = window.innerWidth

      if (vw < 768) {
        //In a mobile view, shoowwww the background title
        this.setState({ visible: true })
      }
    }
  }

  render() {
    return (
      <Container
        top={this.props.top}
        left={this.props.left}
        right={this.props.right}
        relative={this.props.relative}
        opacity={this.props.opacity}
      >
        {/* <CSSTransition classNames="bgTitle"> */}
        {/* <ScrollAnimation
          offset={50}
          animateIn="wacky"
          afterAnimatedIn={() => this.setState({ visible: true })}
          // afterAnimatedIn={visible => console.log(visible)}
          afterAnimatedOut={() => this.setState({ visible: false })}
          // afterAnimatedOut={visible => console.log(visible)}
        > */}
        {(this.props.visible || this.state.visible) && (
          <Title
            // className="bigTitle"
            black={this.props.black}
            yellow={this.props.yellow}
            left={this.props.left}
            right={this.props.right}
            blue={this.props.blue}
          >
            {this.state.title.map((word, index) => (
              <span key={index}>{word} </span>
            ))}
          </Title>
        )}
        {/* </ScrollAnimation> */}
        {/* </CSSTransition> */}
      </Container>
    )
  }
}

export default BackgroundTitle
