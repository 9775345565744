import React from "react"
import styled from "styled-components"

const Container = styled.footer`
  height: 20vh;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  background-color: white;
  /* background: linear-gradient(
    180deg,
    rgba(249, 237, 36, 1) 0%,
    rgba(253, 248, 174, 1) 18%,
    rgba(255, 255, 255, 1) 100%
  ); */
`
const Credit = styled.div`
  font-family: "Recursive";
  font-variation-settings: "wght" 300, "PROP" 0, "XPRN" 0;

  text-align: center;

  a {
    color: black;
  }
`

const Footer = props => (
  <Container>
    <Credit>
      This website was designed by{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.agyei.design"
      >
        Agyei Archer
      </a>{" "}
      and uses{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.recursive.design/"
      >
        Recursive
      </a>{" "}
      by Arrow Type for text.
    </Credit>
  </Container>
)

export default Footer
