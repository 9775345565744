import React from "react"
import styled, { keyframes } from "styled-components"
import "./layout.css"
import { Link } from "gatsby"
import { FaEnvelope } from "react-icons/fa"
import ContactFlyout from "./contact-flyout"

const wobble = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-15px) rotate(-6deg);
            transform: translateX(-15px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(7.5px) rotate(6deg);
            transform: translateX(7.5px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-7.5px) rotate(-3.6deg);
            transform: translateX(-7.5px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(4.5px) rotate(2.4deg);
            transform: translateX(4.5px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg);
  }
`

const Container = styled.div`
  width: 100%;
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
`
const EmailContainer = styled.div`
  padding: 10px;
  :hover {
    -webkit-animation: ${wobble} 0.8s both;
    animation: ${wobble} 0.8s ease both;
  }

  @media (max-width: 500px) {
    padding: 0 5px 0 0;
  }
`
const Email = styled.div`
  -webkit-animation: ${wobble} 0.8s 30s 1 both;
  animation: ${wobble} 0.8s 30s 1 both;
  cursor: pointer;
`
const MenuContainer = styled.nav``
const MenuLinks = styled.ul`
  display: flex;
  margin: 0;
  flex-direction: row;
  list-style: none;
`
const MenuItem = styled.li`
  color: white;
  font-family: "Recursive";
  font-variation-settings: "wght" 550, "PROP" 0, "XPRN" 0;

  text-transform: uppercase;
  margin: 0 30px 0 0;

  @media (max-width: 600px) {
    margin: 0 10px 0 0;
  }

  :last-child {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.4s ease;

    :hover {
      font-variation-settings: "wght" 850, "PROP" 0, "XPRN" 0;
    }
  }
`

class Menu extends React.PureComponent {
  state = {
    menuOpen: false,
  }
  componentDidMount() {
    // console.log(this.props.page)
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  render() {
    return (
      <Container>
        <ContactFlyout closeMenu={this.closeMenu} open={this.state.menuOpen} />
        <EmailContainer>
          <Email
            onClick={() =>
              this.setState({ menuOpen: !this.state.menuOpen }, () =>
                console.log(this.state)
              )
            }
          >
            <FaEnvelope size={25} color="white" />
          </Email>
        </EmailContainer>
        <MenuContainer>
          <MenuLinks>
            <MenuItem>
              <Link to="/">Home</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about">About</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/work">Work</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/connections">Connections</Link>
            </MenuItem>
          </MenuLinks>
        </MenuContainer>
      </Container>
    )
  }
}

export default Menu
