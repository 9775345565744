/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Footer from "./footer"
import Menu from "./menu"

class Layout extends React.PureComponent {
  render() {
    return (
      <>
        <Menu page={this.props.page} />
        <main>{this.props.children}</main>
        {!this.props.noFooter && <Footer />}
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
